@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import "~include-media/dist/_include-media.scss";
@import "css/w3";

$gray: #f2f2f2;
$max-width: 1440px;
$header-width: 1200px;

$breakpoints: (phone: 320px, tablet: 768px, desktop: 1440px);
$fa-font-path: '@fortawesome/fontawesome-free/webfonts';

html, body {
  margin: 0;
  padding: 0;
}

.bg-gray {
  background: $gray;
}

.main-containers {

  width: 100%;
  max-width: $max-width;
  margin: 0 auto;

  @include media("<tablet") {
    padding: 0 20px;
  }
  @include media(">=desktop") {
    display: flex;
  }
}

.half_container{
  width: 50%;
  max-width: $max-width;
  margin: 0 auto;

  @include media("<tablet") {
    padding: 0 20px;
  }
  @include media(">=desktop") {
    display: flex;
  }
}

.page-explanation {
  padding-bottom: 28px;
  margin-bottom: 40px;
}

.app_button {
  width: 172px;
  height: 46px;
  cursor: pointer;
  border-radius: 5px;
  background: #E1052D;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
}

.app_button_index {
  width: 248px;
  height: 46px;
  border-radius: 5px;
  background: #E1052D;
  cursor: pointer;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
}

.button_container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 30px;
  @include media("<tablet") {
    flex-direction: column;
  }
}

.lower_button{
  @include media("<tablet") {
    margin-top: 50px;
  }
}
.app_form {
  width: 200px;
  height: 46px;
  border-radius: 5px;
  background: #E1052D;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  display: block;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 40px;
}

.join_desc{
  align-items: center;
  display: flex;
  @include media("<tablet") {
    justify-content: center
  }
}

.app_button_join_en {
  width: 185px;
  height: 46px;
  border-radius: 5px;
  background: #E1052D;
  color: white;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
}

#header_container {
  max-width: $header-width;
  margin: 0 auto;
  padding: 25px 12px;
  align-items: center;
  @include media(">=phone") {
    display: flex;
  }
}
#intro-video{
  @include media(">=phone") {
    width:100%;
  }
}
#lateral_header_container {
  display: flex;
  height: 100%;
  flex-direction: row;
}

.contained-element {
  max-width: $max-width;
  margin: 0 auto;
  padding: 20px;
}


#footer {
  display: flex;
  border: none;
  flex-direction: row;
  width: 100%;
  @include media("<tablet") {
    flex-direction: column;
  }

}

#footer .main-containers {
  justify-content: space-evenly;
  align-items: center;
}

#language_selector {
  display: flex;
  @include media("<tablet") {
    display: none;
  }
}

.spanish_button {
  font-family: "Muli", sans-serif;
}

.english_button {
  font-family: "Muli", sans-serif;
}

#app_logo {
  flex-grow: 1;
  @include media("<tablet") {
    padding: 0 12px;
  }
}

#lateral_nav {
  visibility: hidden;
  width: 70%;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  right: 0;
  height: 100%;
  color: white;
  text-align: right;
  background: #E1052D;
  @include media("<tablet") {
    visibility: visible;
  }
}

.w3-button {
  width: 15%;
}

.topnav {
  flex-grow: 7;
  font-family: 'Montserrat', sans-serif;
  font-size: 17px;
  padding: 2%;
  color: #67696E;
  @include media("<tablet") {
    display: none;
  }

}

.topnav a {
  color: inherit;
  margin: 14px;
  text-decoration: none;
}

.language_selector {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-weight: 900;
  font-family: 'Muli', sans-serif;
  @include media("<tablet") {
    width: fit-content;
    margin-left: 77%;
  }

}

#lateral_language {
  display: none;
  @include media("<tablet") {
    display: flex;
    width: fit-content;
  }

}

#lateral_bar_button {
  display: none;
  @include media("<tablet") {
    display: block;
  }
}

#common_language {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  color: #E1052D;
  @include media("<tablet") {
    display: none;
  }


}


.labelform {
  margin-left: 10px;
  font-size: 14px;
}

::placeholder {
  padding: 5px;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  text-align: left;
}




#break_hate_container {
  display: flex;
  flex-direction: column;

}

#break {
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: white;
  margin-left: 5%;
  width: 100%;

}

#close_lateral_button {
  width: 50px;
  height: 50px;
}

#lateral_bar_button {
  width: 100px;
  height: 100px;
}

.w3-bar-block .w3-bar-item{
  width: 100%;
  text-align: right;
}


#intro_text {
  @include media(">=desktop") {
    width: 80%;
  }

}

#footer_container_red{
  padding: 3%;
  display:flex;
  flex-direction: row;
  border:none;
  background: #E1052D;
  width: 55%;
  @include media("<tablet") {
    padding: 8%;
    width: 100%;
    flex-direction: column;
  }

}

.field_error{
  display: none;
  color: red;
  font-weight: 600;
}

.privacy_error{
  display: none;
  color:red;
  font-weight: 600;
}

#selected-menu-item{
  color: #E1052D;
  text-decoration: line-through;
  font-weight: 600;
}

#selected-lateral-item{
  text-decoration: line-through;
  font-weight: 600;
}

.success{
  display: none;
  color: green;
  font-weight: 600;
}

#footer_container_white {
  display: flex;
  width: 50%;

  @include media("<tablet") {
    width: 100%;
    flex-direction: column;
  }
}

#break_logos{
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-around;

  @include media("<tablet") {
    width: 100%;
    flex-direction: column;
  }
}

#fad_logo_footer{
  min-width: 100px;
  width:50%;
  margin-left: 10%;
  @include media("<tablet"){
    width: 25%;
    margin: 24px 0;
  }
}

#mad_logo_footer{
  width: 50%;
  margin-left: 10%;
  @include media("<tablet") {
    min-height: auto;
  }
}

#google_logo_footer{
  max-width: 200px;
}

#with_support_container{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10%;

  @include media("<tablet") {
    margin-top: 12px;
  }
}

.about-element, .investigation-element, .resources-element{
  padding-top: 80px;
}

#common_language a, #lateral_language a{
  text-decoration: none;
}

.language_selected{
  border-bottom: 2px solid #e60000;
}

.language_selected_white{
  border-bottom: 2px solid white;
}



.guide{
  display: flex;
  padding-bottom: 40px;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-evenly;
  @include media("<tablet") {
    flex-direction: column-reverse;
  }
}

.guide_title {
  display: flex;
    flex-direction: column;
    font-family: "Muli", sans-serif;
    font-size: 40px;
    font-weight: 900;
    width: 36%;
    align-items: end;
  @include media("<tablet") {
    flex-direction: column;
    font-size: 30px;
    width: 100%;
    margin: 0 auto;
  }
}

.guide_title_text {
  text-align: right;
  line-height: 1em;
  @include media("<tablet") {
    flex-direction: row;
    font-size: 30px;
  }
}


.guide_desc {
  font-family: "Montserrat", regular;
    font-size: 17px;
    margin-right: 15%;
    margin-top: 3%;
    width: 30%;
  @include media("<tablet") {
    width: 95%;
    margin-left: 10px;
  }
}

.guide-text{
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
}

.guide-image{
  width: 223px;
  height: 300px;
  object-fit: contain;
}

